import { FC } from "react";
import { View } from "react-native";
import { Divider, IconButton } from "react-native-paper";
import { A } from "@expo/html-elements";

import DisplayIfPresent from "../shared/DisplayIfPresent";
import { Business } from "../../types";
import Websites from "./Websites";

interface BusinessInformationProps {
  business: Business;
}

interface SocialLinkProps {
  url?: string;
  icon: "facebook" | "twitter";
}

const SocialLink: FC<SocialLinkProps> = ({ url, icon }) => {
  if (!url) return null;

  return (
    <A
      target="_blank"
      rel="noopener noreferrer"
      href={url}
    >
      <IconButton icon={icon} size={32} />
    </A>
  );
}

const BusinessInformation: FC<BusinessInformationProps> = ({
  business: {
    phoneNumbers = [],
    websites = [],
    yearBusinessOpened,
    firstSeen,
    lastSeen,
    createdAt,
    metadata: {
      FB_URL: fbUrl,
      Twitter: twitterUrl,
      Parcel_ID: parcelId,
      Unique_ID: uniqueId,
    },
  },
}) => {
  const hasExtraSection = firstSeen ||
    lastSeen ||
    fbUrl ||
    twitterUrl ||
    createdAt ||
    uniqueId ||
    parcelId;

  if (
    !phoneNumbers.length &&
    !websites.length &&
    !yearBusinessOpened &&
    !hasExtraSection
  ) return null;

  const createdAtStr = !createdAt ? null : createdAt.toDate().toISOString().slice(0, 10);

  return (
    <>
      <Divider style={{ marginTop: 6, marginBottom: 6 }} />

      <DisplayIfPresent label="Phone" text={phoneNumbers[0]} />
      <Websites websites={websites} />

      <View style={{ flexDirection: "row" }} >
        <SocialLink icon="facebook" url={fbUrl} />
        <SocialLink icon="twitter" url={twitterUrl} />
      </View>

      {hasExtraSection && <Divider style={{ marginTop: 6, marginBottom: 6 }} />}

      <DisplayIfPresent label="Parcel ID" text={parcelId} />
      <DisplayIfPresent label="Unique ID" text={uniqueId} />
      <DisplayIfPresent label="Year Opened" text={yearBusinessOpened} />
      <DisplayIfPresent label="First Seen" text={firstSeen} />
      <DisplayIfPresent label="Last Seen" text={lastSeen} />
      <DisplayIfPresent label="Data As of" text={createdAtStr} />

    </>
  );
};

export default BusinessInformation;
