// NOTE: Candidate for extraction into @reasongcp/react-fire-sub.
// NOTE: Copied from auditaware/apps/case-web

import { useMemo } from "react";
import { useAuth } from "@reasongcp/react-fire-sub";
import { setContext } from "@apollo/client/link/context";
import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";

export type ApolloClientWithAuth = ReturnType<typeof useApolloClientWithAuth>;

/*
 * Initializes the client via a memoized hook so that we can use the current
 * user to inject Firebase auth credentials that are fresh into GQL requests.
 */
export const useApolloClientWithAuth = (uri: string) => {
  const user = useAuth();

  return useMemo(() => {
    const link = createHttpLink({
      uri: uri + "graphql",
    });

    const authLink = setContext(async (_, { headers }) => {
      const jwt = (await user?.getIdToken()) || "";
      return {
        headers: {
          ...headers,
          authorization: jwt,
        },
      };
    });

    return new ApolloClient({
      link: authLink.concat(link),
      cache: new InMemoryCache(),
    });
  }, [uri, user]);
};
