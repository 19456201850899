import { View } from "react-native";

import DetailsView from "./BusinessDetails";
import FilterButton from "./filterButton";
import BusinessListSearch from "./businessListSearch";
import SubjectList from "./SubjectList";

import SubjectNavigator from "./SubjectNavigator";

const BusinessListScreen = () => {
  return (
    <>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-around",
          marginTop: 16,
          marginBottom: 24,
        }}
      >
        <BusinessListSearch />
        <FilterButton />
      </View>
      <SubjectList.Businesses />
    </>
  );
};

const BusinessListNavigation = () => {
  return (
    <SubjectNavigator
      subjectType="Business"
      listComponent={BusinessListScreen}
      detailComponent={DetailsView}
    />
  );
};

export default BusinessListNavigation;
