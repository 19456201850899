import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { Observable } from "zen-observable-ts";

import {
  Business,
  BusinessCategories,
  BusinessCategory,
  BusinessStatusesTerminal,
} from "../types";
import Fuse from "fuse.js";
import { AppDispatch, RootState } from "./store";
import { notifyAndLogError } from "./snackbarSlice";
import { subscribeBusiness, subscribeBusinesses } from "../Firebase/queries/businesses";

export interface BusinessListState {
  businesses: Business[];
  selectedBusiness: Business | null;
  filterOptions: BusinessCategory[];
  businessSearchText: string;
  loadingBusinesses: boolean;
}

const initialState: BusinessListState = {
  businesses: [],
  selectedBusiness: null,
  filterOptions: [],
  businessSearchText: "",
  loadingBusinesses: false,
};

const businessList = createSlice({
  name: "businessList",
  initialState,
  reducers: {
    setSelectedBusiness: (state, action: PayloadAction<Business | null>) => {
      state.selectedBusiness = action.payload;
    },

    setBusinesses: (state, action: PayloadAction<Business[]>) => {
      state.businesses = action.payload;
    },
    setFilterOptions: (state, action: PayloadAction<BusinessCategory>) => {
      const option = action.payload;

      if (state.filterOptions.includes(option)) {
        const index = state.filterOptions.indexOf(option);

        state.filterOptions = [
          ...state.filterOptions.slice(0, index),
          ...state.filterOptions.slice(index + 1),
        ];
      } else {
        state.filterOptions = [...state.filterOptions, option];
      }
    },
    setBusinessSearchText: (state, action: PayloadAction<string>) => {
      state.businessSearchText = action.payload;
    },
    setLoadingBusinesses: (state, action: PayloadAction<boolean>) => {
      state.loadingBusinesses = action.payload;
    },
  },
});

export const {
  setBusinesses,
  setFilterOptions,
  setBusinessSearchText,
  setSelectedBusiness,
  setLoadingBusinesses,
} = businessList.actions;

export default businessList.reducer;

// Thunks

// subscribeBusinessesToFirestore is a thunk that subscribes to the
// businesses collection in Firestore and updates the Redux store
// with the latest data. It also updates the selectedBusiness if
// it is in the store. Any errors are logged to the console and
// a snackbar is shown to the user.
export const subscribeBusinessesToFirestore = (orgId: string) => (
  dispatch: AppDispatch,
  getState: () => RootState,
) => {
  dispatch(setLoadingBusinesses(true));
  const {
    loadingBusinesses,
    selectedBusiness,
  } = getState().businessList;

  const next = (businesses: Business[]) => {
    dispatch(setBusinesses(businesses));
    if (loadingBusinesses) dispatch(setLoadingBusinesses(false));

    if (selectedBusiness) {
      const currBusiness = businesses.find(
        (b) => b.id === selectedBusiness.id,
      );
      dispatch(setSelectedBusiness(currBusiness || null));
    }
  };

  const error = (err: Error) =>
    dispatch(notifyAndLogError(err, "Error loading Businesses"));

  return subscribeBusinesses(orgId).subscribe(next, error);
};

export const subscribeBusinessToFirestore = (orgId: string) => (
  dispatch: AppDispatch,
  getState: () => RootState,
) => {
  const { selectedBusiness } = getState().businessList;

  if (!selectedBusiness) return Observable.of(null).subscribe(() => null);

  return subscribeBusiness(orgId, selectedBusiness.id).subscribe(
    (biz: Business) => dispatch(setSelectedBusiness(biz)),
    (err: Error) => dispatch(notifyAndLogError(err, "Error loading Business"))
  );
};

// Selectors

export const selectBusinesses =
  (state: RootState) => state.businessList.businesses;
export const selectFilterOptions =
  (state: RootState) => state.businessList.filterOptions;
export const selectLoadingBusinesses =
  (state: RootState) => state.businessList.loadingBusinesses;
export const selectSelectedBusiness =
  (state: RootState) => state.businessList.selectedBusiness;

// Find the business in the business list with the given ID.
export const selectBusinessWithId = createSelector(
  [
    selectBusinesses,
    (state: RootState, id: string) => id,
  ],
  (businesses, id) => businesses.find((b) => b.id === id) || null,
);

export const selectHasSelectedBusiness = createSelector(
  [selectSelectedBusiness],
  (selectedBusiness) => !!selectedBusiness,
);

export const selectBusinessSearchText = (state: RootState) =>
  state.businessList.businessSearchText;

export const selectFilteredBusinesses = createSelector(
  [selectBusinesses, selectFilterOptions, selectBusinessSearchText],
  (businesses, options, businessSearchText) => {
    const businessCategories = BusinessCategories as ReadonlyArray<string>;

    const categories = options.filter((o) => businessCategories.includes(o));
    const orderBy = categories.length === 1 && categories[0] === "New" ? "score" : null;

    const filteredBusinesses = businesses.filter((b) => {
      const isTerminal = (
        BusinessStatusesTerminal as ReadonlyArray<string>
      ).includes(b.status);

      if (isTerminal) return false;
      if (!options.length) return true; // if there are no options

      return categories.length ? categories.includes(b.category) : true;
    });

    const orderedBusinesses = filteredBusinesses.sort((a, b) => {
      if (orderBy !== "score") return 0;
      return Number(b.metadata?.Score || "0") - Number(a.metadata?.Score || "0");
    });

    const fuse = new Fuse(orderedBusinesses, {
      keys: ["name", "address"],
      isCaseSensitive: false,
      includeScore: true,
      threshold: 0.4,
    });

    const searchResults =
      businessSearchText !== ""
        ? fuse.search(businessSearchText).map((s) => s.item)
        : orderedBusinesses;

    return searchResults;
  }
);
