import { configureStore } from "@reduxjs/toolkit";
import businessList from "./businessListSlice";
import residenceList from "./residenceListSlice";
import user from "./userSlice";
import notes from "./notesSlice";
import attachments from "./attachmentsSlice";
import organization from "./organizationSlice";
import snackbar from "./snackbarSlice";

export const store = configureStore({
  reducer: {
    businessList,
    residenceList,
    user,
    organization,
    snackbar,
    notes,
    attachments,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // Until we figure out how to ignore only Firebase timestamps, disable
      // the serialization check as it's too noisy to be useful.
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppAction = Parameters<AppDispatch>[0];
