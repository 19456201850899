import { v4 as uuidv4 } from "uuid";
import { collection, doc, orderBy, serverTimestamp, setDoc } from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import { Attachment } from "../../lib/types/attachment";
import { db, storage } from "../firebase";
import { subscribeCollection } from "./helpers";


export type CreateAttachmentArgs = {
  contentType: string;
  uuid: string;
  fileName: string;
  label: string;
  storageRefPath: string;
  url: string;
  parentPath: string,
}

export const subscribeAttachments = (parentPath: string) => {
  return subscribeCollection<Attachment>(
    collection(db, `${parentPath}/attachments`),
    orderBy("createdAt", "desc"),
  );
};

export const createAttachment = (
  { parentPath, ...attachmentArgs }: CreateAttachmentArgs
) => {
  const attachmentId = uuidv4();
  const currentDateTime = serverTimestamp();
  const attachmentData: Attachment = {
    ...attachmentArgs,
    createdAt: currentDateTime,
    updatedAt: currentDateTime,
    uuid: attachmentId,
  };

  return setDoc(
    doc(db, `${parentPath}/attachments`, attachmentId),
    attachmentData
  );
}

// Process attachments by adding download URLs to all of the objects. Retry
// any failures once before returning an error.
export const getAttachmentUrl: (attachment: Attachment) => Promise<string> =
  (attachment) => {
    const attachmentRef = ref(storage, attachment.storageRefPath);
    return getDownloadURL(attachmentRef)
      .catch(async (error) => {
        console.error(error);
        console.warn("Failed to get Attachment DownloadURL. Retrying in 2 seconds...");

        return new Promise((resolve) => setTimeout(resolve, 2000)).then(() =>
          getDownloadURL(attachmentRef).catch((error) => {
            console.error(`Failed to get Attachment '${attachment.storageRefPath}'.`);
            console.error(error);

            // Don't add a downloadUrl to the attachment if we can't get it.
            return undefined;
          })
        );
      });
  };
