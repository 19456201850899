import { FC, Fragment } from "react";
import { A } from '@expo/html-elements';
import DisplayLabel from "../shared/DisplayLabel"
import DisplayInfo from "../shared/DisplayInfo"

interface WebsitesProps {
  websites: string[];
}

const padProtocol = (w: string) => w.slice(0, 4) === "http" ? w : `https://${w}`;

const Websites: FC<WebsitesProps> = ({ websites }) => {
  if (!websites.length) return null;
  
  return <>{websites.map((website: string, i: number) => (
    <Fragment key={i}>
      <DisplayLabel text={i === 0 ? "Website" : "Additional Website"} />
      <A target="_blank" rel="noopener noreferrer" href={padProtocol(website)}>
        <DisplayInfo text={website} />
      </A>
    </Fragment>
  ))}</>;
};

export default Websites;
