import gql from "graphql-tag";
import { useApolloClient } from "@apollo/client";
import { Firestore, collection, getDocs } from "firebase/firestore";
import { pick } from "lodash";

import * as aa from "./auditawareTypes";

import { Business, User } from "../types";

interface CreateArgs {
  db: Firestore;
  orgId: string;
  user: User;
  business: Business;
  apolloClient: ReturnType<typeof useApolloClient>;
}

type CreateSubjectWithCaseResult = {
  createSubject: {
    id: string;
    cases: {
      id: string;
    }[];
  };
};

const CREATE_SUBJECT = gql`
  mutation CreateSubjectWithCase(
    $orgId: ID!,
    $input: SubjectInput!,
    $caseInput: CaseInput!,
  ) {
    createSubject(orgId: $orgId, input: $input, caseInput: $caseInput) {
      id
      cases {
        id
      }
    }
  }
`;

const emptyContact: aa.ContactInput = {
  primaryName: "",
  secondaryName: "",
  address: "",
  city: "",
  stateAbbr: "",
  zipCode: "",
  phoneNumber: "",
  email: "",
};

const formatEmail = (email: unknown) => {
  if (typeof email === "string") {
    return email;
  }

  if (Array.isArray(email) && email.length > 0) {
    return email[0];
  }
  return null;
};

const exportSubjectToCaseManager = async ({
  db,
  orgId,
  user,
  business,
  apolloClient,
}: CreateArgs) => {
  const bizEmail = formatEmail(business.email);

  const bizAddress: aa.Address = {
    address: business.address,
    city: business.city,
    stateAbbr: business.stateAbbr,
    zipCode: business.zipCode,
  };

  const input: aa.SubjectInput = {
    subjectType: "Organization",
    sourceId: business.id,
    externalId: business.metadata.Unique_ID,
    firstAuditYear: business.yearBusinessOpened,
    location: {
      ...bizAddress,
      parcelId: business.metadata.Parcel_ID,
      latitude: parseFloat(business.latitude),
      longitude: parseFloat(business.longitude),
      googlePlaceId: business.metadata.Res_Place_ID,
      googleBusinessId: business.metadata.Bus_Place_ID,
    },
    contact: {
      ...bizAddress,
      primaryName: business.name,
      secondaryName: "",
      phoneNumber: business.phoneNumbers[0] || "",
      email: bizEmail,
      website: business.websites[0] || "",
    },
  };

  const notesRef = collection(
    db,
    `organizations/${orgId}/businesses/${business.id}/notes`,
  );
  const noteDocs = await getDocs(notesRef);
  const notesInput = noteDocs.docs.map((doc) => ({
    ...pick(doc.data(), "text"),
  }) as aa.NoteInput);

  const attachmentsRef = collection(
    db,
    `organizations/${orgId}/businesses/${business.id}/attachments`,
  );
  const attachmentDocs = await getDocs(attachmentsRef);
  const attachmentsInput = attachmentDocs.docs.map((doc) => {
    const { id } = doc;
    const {
      url,
      // If there's no file name, use the ID.
      fileName = id,
      // If there's no content type, use a generic binary type.
      contentType = "application/octet-stream",
      label: description,
    } = doc.data();

    return {
      id,
      url,
      fileName,
      contentType,
      description,
    } as aa.AttachmentInput;
  });

  const caseInput: aa.CaseInput = {
    caseType: "Audit",
    status: aa.CaseStatus.ToDo,
    assigneeUid: user.id,
    primaryContact: emptyContact,
    secondaryContact: emptyContact,
    notes: notesInput,
    attachments: attachmentsInput,
  };

  const variables = {
    orgId,
    input: input,
    caseInput,
  };

  const result = await apolloClient.mutate<CreateSubjectWithCaseResult>({
    mutation: CREATE_SUBJECT,
    variables,
  });

  if (result.errors) {
    console.error(result.errors);
    throw new Error(result.errors[0].message);
  }

  if (!result.data) {
    console.error("No data returned from createSubject");
    throw new Error("Case created, but cannot open. Please check Case Manager.");
  }
  return result.data.createSubject;
};

export default exportSubjectToCaseManager;
