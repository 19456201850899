import { store } from "../redux/store";
import { setSnackbar, SetSnackbarPayload, SnackbarSeverity } from "../redux/snackbarSlice";

const partialNotify = (
  defaults: Partial<SetSnackbarPayload> = {},
  dispatch = store.dispatch,
) => (
  message = defaults.message || "",
  payload: Partial<SetSnackbarPayload> = {},
) => {
  return dispatch(setSnackbar({
    severity: "success" as SnackbarSeverity,
    duration: 3000,
    ...defaults,
    ...payload,
    message,
  }));
};

export default partialNotify;
