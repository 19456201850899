import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from './store';

export type SnackbarSeverity = 'success' | 'warning' | 'error' | 'info';

export type SetSnackbarPayload = {
  severity: SnackbarSeverity;
  message: string;
  duration?: number;
  href?: string;
}

export interface SnackbarState {
  isOpen: boolean;
  message: string;
  severity: SnackbarSeverity;
  duration: number;
  href?: string;
}

const initialState: SnackbarState = {
  isOpen: false,
  message: '',
  severity: 'info',
  duration: 3000,
};

export const alertSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    setSnackbar: (
      state,
      action: PayloadAction<SetSnackbarPayload>,
    ) => {
      state.severity = action.payload.severity;
      state.message = action.payload.message;
      state.duration = action.payload.duration || initialState.duration;
      state.href = action.payload.href;
      state.isOpen = true;
    },
    closeSnackbar: (state) => {
      state.isOpen = false;
    },
  },
});

export const { setSnackbar, closeSnackbar } = alertSlice.actions;

export default alertSlice.reducer;

export const notifyAndLogError =
  (err: Error, message?: string) =>
  (dispatch: AppDispatch) => {
    console.error(err);
    dispatch(setSnackbar({
      severity: 'error',
      message: message || err.message,
    }));
  };
