import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Note } from "../lib/types/note";

import { AppDispatch, RootState } from "./store";
import { notifyAndLogError } from "./snackbarSlice";
import { subscribeNotes } from "../Firebase/queries/notes";
import { Organization } from "../types";

export interface NotesState {
  notes: Note[],
}

const initialState: NotesState = {
  notes: [],
};

const notesSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    setNotes: (state, action: PayloadAction<Note[]>) => {
      state.notes = action.payload;
    }
  }
});

export const {
  setNotes,
} = notesSlice.actions;

export default notesSlice.reducer;

// Thunks

export const subscribeResidenceNotesToFirestore = (
  org: Organization,
  residenceId: string,
) => (
  dispatch: AppDispatch,
) => {

  const {
    id: orgId,
    residencesEpochId: epochId,
  } = org;

  const parentPath = `organizations/${orgId}/residence_epochs/${epochId}/residences/${residenceId}`;
  const next = (notes: Note[]) => {
    dispatch(setNotes(notes));
  };
  const error = (err: Error) =>
    dispatch(notifyAndLogError(err, "Error loading Notes"));

  return subscribeNotes(parentPath).subscribe(next, error);
};

// Selectors

export const selectNotes = ({ notes }: RootState) => {
  return notes.notes;
}
