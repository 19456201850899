import { useCallback } from "react";
import { Divider, IconButton, Text } from "react-native-paper";
import { ScrollView, StyleSheet, View } from "react-native";
import { NavigationContainer } from "@react-navigation/native";

import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";

import { Organization } from "../../types";
import { useAppTheme } from "../../theme";
import { Residence } from "../../lib/types/residence";
import ResidenceChip from "../../components/shared/ResidenceChip";
import FormattedAddress from "../shared/FormattedAddress";
import { GPlacesActionItem } from "../shared/GPlacesLink";
import DisplayIfPresent from "../shared/DisplayIfPresent";

import useAppNavigation from "../../hooks/useAppNavigation";
import { useResidenceFromRoute } from "../../hooks/useBusinessFromRoute";
import useSelectSubject from "../../hooks/useSelectSubject";

import { useAppSelector } from "../../redux/hooks";
import { selectOrganization } from "../../redux/organizationSlice";
import { setResidence } from "../../redux/residenceListSlice";

import DisplayLabel from "../shared/DisplayLabel";
import ActionFlow from "../shared/ActionFlow";
import PlatformScrollView from "../shared/PlatformScrollView";

import ResidenceNotes from "./ResidenceNotes";
import RelatedResidences from "./RelatedResidences";
import residenceStyles from "../DetailStyles";
import { dismissResidenceCase } from "../../Firebase/queries/cases";
import ResidenceAttachments from "./ResidenceAttachments";
import partialNotify from "../../lib/partialNotify";

type ResidenceContentProps = {
  organization: Organization;
  residence: Residence;
};

const Tabs = createMaterialTopTabNavigator();

const stylesheet = StyleSheet.create({
  residenceName: {
    marginBottom: 6,
    fontSize: 22,
    fontWeight: "bold",
  },
  header: {
    fontSize: 16,
    fontWeight: "bold",
    marginTop: 12,
  },
  actions: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingRight: 12,
  },
  tabViewContainer: {
    // This minHeight is a total hack and only barely works.
    minHeight: 500,
  },
  tabView: {
    height: "auto",
  },
  tabNav: {
    height: "auto",
    marginTop: 24,
  },
  tabScreen: {
    backgroundColor: "transparent",
  },
});

const dismissConfirmation = partialNotify();

const ResidenceContent = ({ organization, residence }: ResidenceContentProps) => {
  const navigation = useAppNavigation();
  const theme = useAppTheme();

  const handleEditPressed = useCallback(() => {
    navigation.navigate("Edit_Residence", { id: residence.id });
  }, [navigation, residence.id]);

  const handleBackPress = useCallback(() => {
    navigation.navigate("Residence_List");
  }, [navigation]);

  const handleDismissCase = useCallback(() => {
    if (!organization.residencesEpochId) {
      return;
    }
    dismissResidenceCase({
      orgId: organization.id,
      epochId: organization.residencesEpochId,
      residenceId: residence.id,
    })
      .then(() => {
        navigation.navigate("Residence_List");
        dismissConfirmation("Case dismissed");
      })
      .catch((error) => {
        console.error("Error dismissing case", error);
      });
  }, [navigation, residence, organization]);

  return (
    <PlatformScrollView style={residenceStyles.detailsContainer}>
      <View style={stylesheet.actions}>
        <IconButton icon="arrow-left" onPress={handleBackPress} style={{ margin: 0 }} />
      </View>

      <View style={residenceStyles.detailBlock}>
        <Text style={stylesheet.residenceName}>{residence.name}</Text>

        <View style={{ alignItems: "flex-start", marginBottom: 6 }}>
          <ResidenceChip category={residence.category} />
        </View>

        <FormattedAddress address={residence} />

        <ActionFlow>
          <ActionFlow.Item
            text="Edit"
            icon="file-edit-outline"
            onPress={handleEditPressed}
          />
          <ActionFlow.Item text="Create Case" icon="file-document-outline" />
          <ActionFlow.Item
            text="Dismiss"
            icon="close-circle"
            confirmMessage="Are you sure you want to dismiss this item?"
            onPress={handleDismissCase}
          />
          <GPlacesActionItem
            address={residence.metadata.cleanAddress}
            placeId={residence.metadata.resPlaceId}
          />
        </ActionFlow>

        <Divider style={{ marginTop: 6, marginBottom: 6 }} />

        <View>
          <DisplayIfPresent label="Parcel ID" text={residence.parcelId} selectable />

          <DisplayIfPresent
            label="Exemption Percent"
            text={String(residence.exemption)}
          />
        </View>

        <View>
          <DisplayLabel text="Contact Information" />
          <FormattedAddress
            address={residence.contact}
            style={{ fontWeight: "400", fontSize: 14 }}
          />
        </View>
      </View>

      <View style={stylesheet.tabViewContainer}>
        <NavigationContainer
          documentTitle={{
            // This feels like a hack. Ensure this sub-navigator doesn't update
            // the page title to something like "Related" or "Notes".
            formatter: () => "Digital Canvas | Residence Insights",
          }}
          independent
        >
          <Tabs.Navigator
            initialRouteName="Related"
            style={stylesheet.tabNav}
            sceneContainerStyle={stylesheet.tabScreen}
            screenOptions={{
              tabBarActiveTintColor: theme.colors.primary,
              tabBarStyle: {
                backgroundColor: theme.colors.background,
              },
              tabBarLabelStyle: {
                color: theme.colors.onBackground,
              },
            }}
          >
            <Tabs.Screen name="Related">
              {() => (
                <ScrollView>
                  <RelatedResidences
                    appNavigation={navigation}
                    organization={organization}
                    residence={residence}
                  />
                </ScrollView>
              )}
            </Tabs.Screen>

            <Tabs.Screen name="Notes">
              {() => (
                <ScrollView>
                  <ResidenceNotes organization={organization} residence={residence} />
                </ScrollView>
              )}
            </Tabs.Screen>

            <Tabs.Screen name="Attachments">
              {() => (
                <ScrollView>
                  <ResidenceAttachments
                    organization={organization}
                    residence={residence}
                  />
                </ScrollView>
              )}
            </Tabs.Screen>
          </Tabs.Navigator>
        </NavigationContainer>
      </View>
    </PlatformScrollView>
  );
};

export default function ResidenceDetails() {
  const organization = useAppSelector(selectOrganization);
  const residence = useResidenceFromRoute();
  useSelectSubject(residence, setResidence);

  if (!residence || !organization) {
    return <></>;
  }

  return <ResidenceContent organization={organization} residence={residence} />;
}
