import { useCallback, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import { Keyboard, Platform, TouchableWithoutFeedback, View } from "react-native";

// eslint-disable-next-line max-len
import { SelectedItem } from "react-native-paper-select/lib/typescript/interface/paperSelect.interface";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { Controller, useForm } from "react-hook-form";
import { Button, TextInput, useTheme } from "react-native-paper";
import "react-native-get-random-values";

import { ref, uploadBytes } from "firebase/storage";
import { arrayUnion, doc, runTransaction, serverTimestamp, updateDoc } from "firebase/firestore";

import { Attachment, Business, BusinessCategories, Note, Organization, User } from "../../types";

import { db, storage } from "../../Firebase/firebase";
import { selectUser } from "../../redux/userSlice";
import { selectOrganization } from "../../redux/organizationSlice";
import { useAppSelector } from "../../redux/hooks";
import partialNotify from "../../lib/partialNotify";

import MyImagePicker from "../imagePicker";
import StyledPaperSelect from "../shared/StyledPaperSelect";
import useBusinessFromRoute from "../../hooks/useBusinessFromRoute";
import useAppNavigation from "../../hooks/useAppNavigation";

type Image = {
  imageUrl: string;
  imageDescription: string;
  fileName: string;
  contentType: string;
};

type BusinessEditProps = {
  business: Business;
  organization: Organization;
  user: User;
};

export type BusinessEditInput = {
  note: string;
  images: Image[];
  category: string;
  status: string;
};

const businessCategoryListItems = BusinessCategories.map((category) => {
  return { _id: category, value: category };
});

const notifySaved = partialNotify({
  message: "Updates saved successfully!",
  severity: "success",
});

const notifyError = partialNotify({
  message: "Error saving updates.",
  severity: "error",
});

const logAndNotifyError = (err: Error) => {
  console.error(err);
  notifyError();
};

const BusinessEditForm = ({ business, organization, user }: BusinessEditProps) => {
  const busId = business.id;
  const orgId = organization.id;

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isValid, dirtyFields, isSubmitting },
  } = useForm<BusinessEditInput>({
    defaultValues: {
      note: "",
      category: business.category,
      status: business.status,
    },
  });

  // Reset the form state when the component unmounts.
  // TODO: This was moved from updateDatabase, but the next thing the callback
  // does is navigate away, which will cause this component to unmount. At
  // that point, resetting the form is likely unnessecary(?)
  useEffect(() => reset, [reset]);
  const theme = useTheme();
  const navigation = useAppNavigation();

  const goBack = useCallback(() => {
    navigation.navigate("Business_List");
  }, [navigation]);

  const updateCategoryAndStatus = useCallback(
    (data: BusinessEditInput) => {
      const { category, status } = data;
      const docRef = doc(db, `organizations/${orgId}/businesses`, business.id);
      updateDoc(docRef, { category, status });
    },
    [orgId, business]
  );

  // @TODO create card to extract into queries
  const createNote = useCallback(
    async (note: string) => {
      if (!dirtyFields.note) return;
      const noteId = uuidv4();

      const currentDateTime = serverTimestamp();

      const noteData: Note = {
        text: note,
        createdAt: currentDateTime,
        updatedAt: currentDateTime,
        uuid: noteId,
        createdByUid: user.id,
      };

      try {
        await runTransaction(db, async (transaction) => {
          transaction.set(
            doc(db, `organizations/${orgId}/businesses/${busId}/notes`, noteId),
            noteData
          );

          transaction.update(doc(db, `organizations/${orgId}/businesses/${busId}/`), {
            notes: arrayUnion(noteId),
          });
        });
      } catch (err) {
        throw new Error("issue with transaction");
      }
    },
    [orgId, busId, user, dirtyFields.note]
  );

  // @TODO create card to extract into queries (tech debt label)
  const createAttachments = useCallback(
    async (images: Image[]) => {
      if (!dirtyFields.images || images.length == 0) return;

      const imageIds = images.map(() => uuidv4());
      // upload images
      images.forEach(async (image, index) => {
        const uuid = imageIds[index];
        const storageRef = ref(storage, uuid);
        const response = await fetch(image.imageUrl);
        const blob = await response.blob();
        await uploadBytes(storageRef, blob);
      });

      await runTransaction(db, async (transaction) => {
        images.forEach(async (image, index) => {
          const uuid = imageIds[index];
          const storageRef = ref(storage, uuid);
          const fullUrl = `gs://${storageRef.bucket}/${storageRef.fullPath}`;
          const imageData: Attachment = {
            uuid,
            label: image.imageDescription,
            url: fullUrl,
            storageRefPath: uuid,
            createdAt: serverTimestamp(),
            fileName: image.fileName,
            contentType: image.contentType,
          };

          // append to case attachments ids array
          transaction.update(doc(db, `organizations/${orgId}/businesses/${busId}`), {
            attachments: arrayUnion(uuid),
          });

          // create attachment
          transaction.set(
            doc(db, `organizations/${orgId}/businesses/${busId}/attachments`, uuid),
            imageData
          );
        });
      });
    },
    [orgId, busId, dirtyFields.images]
  );

  const updateFields = useCallback(
    (data: BusinessEditInput) =>
      Promise.all([
        createAttachments(data.images),
        createNote(data.note),
        updateCategoryAndStatus(data),
      ]),
    [createAttachments, createNote, updateCategoryAndStatus]
  );

  const updateDatabase = useCallback(
    async (data: BusinessEditInput) => {
      updateFields(data)
        .then(goBack)
        .then(() => notifySaved())
        .catch(logAndNotifyError);
    },
    [goBack, updateFields]
  );

  return (
    <>
      <KeyboardAwareScrollView contentContainerStyle={{ flex: 1 }}>
        <TouchableWithoutFeedback
          onPress={Keyboard.dismiss}
          disabled={Platform.OS === "web"}
        >
          <View>
            <Controller
              control={control}
              name="category"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StyledPaperSelect
                  label="Category"
                  value={value}
                  onSelection={(v: SelectedItem) => onChange(v.text)}
                  arrayList={businessCategoryListItems}
                  multiEnable={false}
                  hideSearchBox={true}
                  theme={theme}
                  dialogCloseButtonText="Cancel"
                  selectedArrayList={businessCategoryListItems.filter(
                    (i) => i.value === value
                  )}
                />
              )}
            />

            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  label="Add Note"
                  mode="outlined"
                  multiline={true}
                  onChangeText={onChange}
                  value={value}
                  // only android
                  numberOfLines={4}
                  style={{ minHeight: 120, marginHorizontal: 20 }}
                />
              )}
              name="note"
            />
            <MyImagePicker control={control} />
            <Button
              mode="contained"
              loading={isSubmitting}
              disabled={!isDirty || !isValid || isSubmitting}
              color={theme.colors.primary}
              style={{
                borderRadius: 4,
                marginHorizontal: 20,
                marginVertical: 20,
              }}
              onPress={handleSubmit(updateDatabase)}
              textColor={theme.colors.onPrimary}
            >
              Save
            </Button>
          </View>
        </TouchableWithoutFeedback>
      </KeyboardAwareScrollView>
    </>
  );
};

export default function BusinessEditFormContainer() {
  const organization = useAppSelector(selectOrganization);
  const user = useAppSelector(selectUser);
  const business = useBusinessFromRoute();

  if (!business || !organization || !user) {
    return <></>;
  }

  return <BusinessEditForm business={business} organization={organization} user={user} />;
}
