import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { createDrawerNavigator } from "@react-navigation/drawer";

import MapScreen from "../screens/Map";
import { DrawerContent } from "../components/drawerContent";
import useThemeName from "../hooks/useThemeName";
import EditResidenceScreen from "../screens/EditResidence";
import AddBusinessScreen from "../screens/addBusiness";
import EditScreen from "../screens/editBusiness";

const Stack = createStackNavigator();
const BusinessStack = createStackNavigator();
const ResidenceStack = createStackNavigator();
const Drawer = createDrawerNavigator();

const BusinessNavigation = () => {
  const themeName = useThemeName();

  return (
    <BusinessStack.Navigator
      screenOptions={{
        title: "Digital Canvas | Business Insights",
        headerTintColor: themeName === "light" ? "black" : "white",
        headerShown: false,
        animationEnabled: true,
        headerTitleStyle: {
          fontWeight: "bold",
        },
        detachPreviousScreen: false,
      }}
      initialRouteName="BI_Map"
    >
      <BusinessStack.Screen name="BI_Map" component={MapScreen.Business} />
      <BusinessStack.Group>
        <BusinessStack.Screen
          name="Add_Business"
          component={AddBusinessScreen}
          options={{
            headerTitle: "Add Business",
            presentation: "transparentModal",
          }}
        />
        <BusinessStack.Screen
          name="Edit_Business"
          component={EditScreen}
          options={{
            headerTitle: "Edit Business",
            presentation: "transparentModal",
          }}
        />
      </BusinessStack.Group>
    </BusinessStack.Navigator>
  );
};

const ResidenceNavigation = () => {
  const themeName = useThemeName();

  return (
    <ResidenceStack.Navigator
      screenOptions={{
        title: "Digital Canvas | Residence Insights",
        headerTintColor: themeName === "light" ? "black" : "white",
        headerShown: false,
        animationEnabled: true,
        headerTitleStyle: {
          fontWeight: "bold",
        },
        detachPreviousScreen: false,
      }}
      initialRouteName="RI_Map"
    >
      <ResidenceStack.Screen name="RI_Map" component={MapScreen.Residence} />
      <ResidenceStack.Group>
        <ResidenceStack.Screen
          name="Edit_Residence"
          component={EditResidenceScreen}
          options={{
            headerTitle: "Edit Residence",
            presentation: "transparentModal",
          }}
        />
      </ResidenceStack.Group>
    </ResidenceStack.Navigator>
  );
};

function DrawerRoutes() {
  return (
    <Drawer.Navigator
      drawerContent={() => <DrawerContent />}
      initialRouteName="Businesses"
    >
      <Drawer.Screen
        name="Businesses"
        component={BusinessNavigation}
        options={{ headerShown: false, title: "Digital Canvas" }}
      />
      <Drawer.Screen
        name="Residences"
        component={ResidenceNavigation}
        options={{ headerShown: false, title: "Digital Canvas" }}
      />
    </Drawer.Navigator>
  );
}

export default function AppStack() {
  const themeName = useThemeName();

  return (
    <Stack.Navigator
      screenOptions={{
        headerTintColor: themeName === "light" ? "black" : "white",
        headerShown: false,
        headerTitleStyle: {
          fontWeight: "bold",
        },
      }}
      initialRouteName="Root"
    >
      <Stack.Screen name="Root" component={DrawerRoutes} />
    </Stack.Navigator>
  );
}
