import { useColorScheme } from 'react-native';

import useThemeMode from './useThemeMode';
import { getThemeName } from  "../lib/AppThemeProvider";

const useThemeName = () => {
  const colorScheme = useColorScheme() || 'light';
  const [mode] = useThemeMode();
  return getThemeName(mode, colorScheme);
};

export default useThemeName;
