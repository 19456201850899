import { useCallback, useState } from "react";
import { TextInput } from "react-native-paper";
import debounce from "lodash.debounce";

import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setBusinessSearchText } from "../redux/businessListSlice";
import { useAppTheme } from "../theme";
import useAppBottomSheet from "../hooks/useAppBottomSheet";

// TODO: Replace this component with the generic one in shared/SearchBox.tsx.
export default function BusinessListSearch() {
  const theme = useAppTheme();
  const dispatch = useAppDispatch();
  const bottomSheet = useAppBottomSheet();

  const { businessSearchText } = useAppSelector((state) => state.businessList);
  const [search, setSearch] = useState(businessSearchText);

  const debounceHandleChange = useCallback(
    (text: string) => {
      const dispatchChange = () => dispatch(setBusinessSearchText(text));
      return debounce(dispatchChange, 300)();
    },
    [dispatch]
  );

  const handleChange = useCallback(
    (text: string) => {
      setSearch(text);
      debounceHandleChange(text);
    },
    [debounceHandleChange]
  );

  const handleFocus = useCallback(() => {
    bottomSheet?.snapToIndex(3);
  }, [bottomSheet]);

  return (
    <TextInput
      value={search}
      onFocus={handleFocus}
      underlineColor="transparent"
      onChangeText={handleChange}
      placeholder="Search"
      placeholderTextColor={theme.colors.onBackground}
      autoCapitalize="words"
      style={{
        width: "80%",
        borderRadius: 4,
        borderWidth: 1,
        borderColor: theme.colors.backgroundBorder,
        backgroundColor: theme.colors.background,
        color: theme.colors.onBackground,
        height: 40,
      }}
    />
  );
}
