import { Timestamp } from "firebase/firestore";

import { Address } from "./address";
import { Contact } from "./contact";
import { SubjectStatus } from "./subjectStatuses";

// TODO: Make BusinessCategories UPPERCASE, or this lowercase.
export const ResidenceCategories = [
  "TRUST",
  "LIVING TRUST",
  "IRREV TRUST",
  "REVOC TRUST",
  "BUSINESS",
  "MULTIPLE HOMES",
  "MULTIPLE IN STATE",
  "MULTIPLE OUT STATE",
] as const;

export type ResidenceCategory = (typeof ResidenceCategories)[number];

export type ResidenceMetadata = {
  cleanAddress: string;
  mailingAddress: string;
  resPlaceId: string;
}

export type ResidenceAttributes =
  Address & {
    name: string;
    category: ResidenceCategory;
    status: SubjectStatus;
    latitude: number;
    longitude: number;
    score: number;
    exemption: number;
    groupId: string;
    parcelId: string;
    uniqueId: string;
    contact: Contact;
    metadata: ResidenceMetadata;
  };

export type Residence =
  ResidenceAttributes & {
    id: string;
    createdAt: Timestamp;
  };
