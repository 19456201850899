import { FC, useEffect, useState } from "react";
import { Image, View } from "react-native";

import { Organization } from "../../types";
import { Residence } from "../../lib/types/residence";
import NoData from "../shared/NoData";

import { selectAttachments, subscribeResidenceAttachmentsToFirestore } from "../../redux/attachmentsSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

import residenceStyles from "../DetailStyles";
import { A } from "@expo/html-elements";
import { getAttachmentUrl } from "../../Firebase/queries/attachments";
import { useAppTheme } from "../../theme";

type AttachmentsProps = {
  organization: Organization;
  residence: Residence;
};

const ResidenceAttachments: FC<AttachmentsProps> = ({
  organization,
  residence,
}) => {
  const dispatch = useAppDispatch();
  const attachments = useAppSelector(selectAttachments);
  const [urls, setUrls] = useState<string[]>([])
  const theme = useAppTheme();

  useEffect(() => {
    const sub = dispatch(
      subscribeResidenceAttachmentsToFirestore(organization, residence.id),
    );
    return () => sub.unsubscribe();
  }, [dispatch, organization, residence]);

  useEffect(() => {
    (async () => setUrls(await Promise.all(attachments.map(getAttachmentUrl))))()
  }, [attachments])

  return (
    <View style={residenceStyles.detailBlock}>
      {attachments.length === 0 && <NoData text="No Attachments" />}
      {attachments.map((attachment, idx) => {
        return (
          <View key={attachment.uuid}>
            <Image
              source={{ uri: urls[idx] }}
              style={{ width: 200, height: 200 }}
            />
            <A
              href={urls[idx]}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: theme.colors.onBackground,
              }}
            >
              {attachment.label}
            </A>
          </View>
        );
      })}
    </View>
  );
};

export default ResidenceAttachments;
