import { useCallback, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { Card, IconButton, Text } from "react-native-paper";

import { selectRelated, setRelated, subscribeRelatedToFirestore } from "../../redux/residenceListSlice";

import { AppNavigation } from "../../hooks/useAppNavigation";
import useAppBottomSheet from "../../hooks/useAppBottomSheet";

import { Organization } from "../../types";
import { Residence } from "../../lib/types/residence";

import NoData from "../shared/NoData";
import FormattedAddress from "../shared/FormattedAddress";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

import residenceStyles from "../DetailStyles";

export type RelatedProps = {
  appNavigation: AppNavigation;
  organization: Organization;
  residence: Residence;
};

type RelatedCardProps = {
  appNavigation: AppNavigation;
  residence: Residence;
};

const stylesheet = StyleSheet.create({
  relatedBlock: {
    height: "auto",
    paddingBottom: 4,
  },
  relatedCard: {
    marginTop: 12,
  },
  relatedCardTitle: {
    marginBottom: 6,
    fontSize: 16,
    fontWeight: "bold",
  },
  relatedCardAddress: {
    marginBottom: 6,
    fontWeight: "400",
    fontSize: 14,
  },
});

const RelatedCard: React.FC<RelatedCardProps> = ({
  residence,
  appNavigation,
}) => {
  const bottomSheet = useAppBottomSheet();
  const handlePress = useCallback(() => {
    bottomSheet?.snapToIndex(2);
    appNavigation.navigate("Residence_Detail", { id: residence.id });
  }, [appNavigation, bottomSheet, residence.id]);

  return (
    <Card
      mode="elevated"
      style={stylesheet.relatedCard}
      onPress={handlePress}
    >
      <Card.Content style={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}>
        <View style={{ flexGrow: 1 }}>
          <Text style={stylesheet.relatedCardTitle}>
            {residence.name}
          </Text>
          <FormattedAddress
            address={residence}
            style={stylesheet.relatedCardAddress}
          />
          <Text>{residence.parcelId}</Text>
        </View>

        <View style={{ flexGrow: 0 }}>
          <IconButton icon="arrow-right" />
        </View>
      </Card.Content>
    </Card>
  );
};

const RelatedResidences: React.FC<RelatedProps> = ({
  organization,
  residence,
  appNavigation,
}) => {
  const dispatch = useAppDispatch();
  const relatedResidences = useAppSelector(selectRelated);

  useEffect(() => {
    if (residence.groupId === null) return;

    const sub = dispatch(subscribeRelatedToFirestore(
      organization.id,
      organization.residencesEpochId || "",
      residence.groupId,
    ));

    return () => {
      dispatch(setRelated([]));
      sub.unsubscribe();
    };
  }, [dispatch, organization, residence]);

  return (
    <View style={[
      stylesheet.relatedBlock,
      residenceStyles.detailBlock,
    ]}>
      {relatedResidences.length === 0 && (
        <NoData text="No Related Residences" />
      )}

      {relatedResidences.map((related) => {
        if (related.id === residence.id) return null;

        return (
          <RelatedCard
            key={related.id}
            residence={related}
            appNavigation={appNavigation}
          />
        );
      })}
    </View>
  );
};

export default RelatedResidences;
