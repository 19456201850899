// NOTE: This file has been abstracted to shared/FilterButton.tsx for use with
// Residence Insights as well. This file should be updated to use that, and
// likely moved to a BusinessDetails/ directory to reflect the new
// organization used by ResidenceDetails/.

import { useCallback, useState } from "react";
import { TouchableOpacity } from "react-native";
import { Text, IconButton, Menu, Checkbox } from "react-native-paper";

import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { selectFilterOptions, setFilterOptions } from "../redux/businessListSlice";
import { BusinessCategories } from "../types";
import { useAppTheme } from "../theme";

export default function FilterButton() {
  const theme = useAppTheme();
  const dispatch = useAppDispatch();
  const filterOptions = useAppSelector(selectFilterOptions);

  const [isOpen, setIsOpen] = useState(false);
  const toggleIsOpen = useCallback(
    () => setIsOpen((isOpen) => !isOpen),
    [],
  );

  return (
    <Menu
      visible={isOpen}
      onDismiss={toggleIsOpen}
      anchor={
        <IconButton
          icon="filter-outline"
          iconColor={theme.colors.onBackground}
          mode="contained"
          containerColor={theme.colors.background}
          accessibilityLabel="filter"
          style={{
            borderRadius: 4,
            borderWidth: 1,
            borderColor: theme.colors.backgroundBorder,
            height: 40,
          }}
          onPress={toggleIsOpen}
        />
      }
    >
      <Text style={{ fontSize: 16, paddingLeft: 10 }}>Filter by:</Text>
      {BusinessCategories.map((option) => {
        return (
          <TouchableOpacity
            key={option}
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginVertical: 4,
            }}
            onPress={() => dispatch(setFilterOptions(option))}
          >
            <Checkbox.Android
              status={filterOptions.includes(option) ? "checked" : "unchecked"}
              onPress={() => dispatch(setFilterOptions(option))}
              color="#3988BD"
            />
            <Text variant="bodyLarge" style={{ marginRight: 8 }}>
              {option}
            </Text>
          </TouchableOpacity>
        );
      })}
    </Menu>
  );
}
