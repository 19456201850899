import { StackScreenProps, createStackNavigator } from "@react-navigation/stack";

import { useAppTheme } from "../theme";
import { RootStackParamList } from "../lib/appNavigation";

type ListComponentProps =
  StackScreenProps<RootStackParamList, "Residence_List" | "Business_List">

type DetailComponentProps =
  StackScreenProps<RootStackParamList, "Residence_Detail" | "Business_Detail">

export type SubjectNavigatorProps = {
  subjectType: "Residence" | "Business";
  listComponent: (props: ListComponentProps) => React.ReactNode;
  detailComponent: (props: DetailComponentProps) => React.ReactNode;
  title?: string;
  initialRouteName?: keyof RootStackParamList;
}

const ListStack = createStackNavigator<RootStackParamList>();

const SubjectNavigator: React.FC<SubjectNavigatorProps> = ({
  subjectType,
  listComponent,
  detailComponent,
  title = `Digital Canvas | ${subjectType} Insights`,
  initialRouteName = `${subjectType}_List`,
}) => {
  const theme = useAppTheme();

  return (
    <ListStack.Navigator
      initialRouteName={initialRouteName}
      screenOptions={{
        headerShown: false,
        animationEnabled: true,
        title,
        cardStyle: { backgroundColor: theme.colors.background },
      }}
    >
      <ListStack.Screen
        name={`${subjectType}_List`}
        component={listComponent}
      />
      <ListStack.Screen
        name={`${subjectType}_Detail`}
        component={detailComponent}
      />
    </ListStack.Navigator>
  );
};

export default SubjectNavigator;
