import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppDispatch, RootState } from "./store";
import { notifyAndLogError } from "./snackbarSlice";
import { subscribeAttachments } from "../Firebase/queries/attachments";
import { Attachment, Organization } from "../types";

export interface AttachmentsState {
  attachments: Attachment[],
}

const initialState: AttachmentsState = {
  attachments: [],
};

const attachmentsSlice = createSlice({
  name: "attachments",
  initialState,
  reducers: {
    setAttachments: (state, action: PayloadAction<Attachment[]>) => {
      state.attachments = action.payload;
    }
  }
});

export const {
  setAttachments,
} = attachmentsSlice.actions;

export default attachmentsSlice.reducer;

// Thunks

export const subscribeResidenceAttachmentsToFirestore = (
  org: Organization,
  residenceId: string,
) => (
  dispatch: AppDispatch,
) => {

  const {
    id: orgId,
    residencesEpochId: epochId,
  } = org;

  const parentPath =
    `organizations/${orgId}/residence_epochs/${epochId}/residences/${residenceId}`;
  const next = (attachments: Attachment[]) => {
    dispatch(setAttachments(attachments));
  };
  const error = (err: Error) =>
    dispatch(notifyAndLogError(err, "Error loading Attachments"));

  return subscribeAttachments(parentPath).subscribe(next, error);
};

// Selectors

export const selectAttachments = ({ attachments, ...root }: RootState) => {
  return attachments.attachments;
}
