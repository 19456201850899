import Pordal from "../components/shared/Pordal";
import BusinessEditFormContainer from "../components/BusinessDetails/BusinessEditFormContainer";

export default function EditScreen() {
  return (
    <Pordal>
      <BusinessEditFormContainer/>
    </Pordal>
  )
}
