import { Button, Text, TextInput, useTheme } from "react-native-paper";
import Pordal from "../components/shared/Pordal";
import { Controller, useForm } from "react-hook-form";
import { useCallback } from "react";
import { useResidenceFromRoute } from "../hooks/useBusinessFromRoute";
import { useAppSelector } from "../redux/hooks";
import { selectOrganization } from "../redux/organizationSlice";
import { selectUser } from "../redux/userSlice";
import { createNote } from "../Firebase/queries/notes";
import partialNotify from "../lib/partialNotify";
import useAppNavigation from "../hooks/useAppNavigation";
import ResidenceImagePicker from "../components/ResidenceDetails/ResidenceImagePicker";
import { Image, StyleSheet, View } from "react-native";
import { v4 as uuidv4 } from "uuid";
import { ref, uploadBytes } from "firebase/storage";
import { db, storage } from "../Firebase/firebase";
import { arrayUnion, doc, runTransaction, serverTimestamp } from "firebase/firestore";
import { Attachment } from "../types";

export type IFormInput = {
  category: string;
  note: string;
  status: string;
  images: Image[];
};

const notifySaved = partialNotify({
  message: "Note saved successfully!",
  severity: "success",
});

const stylesheet = StyleSheet.create({
  residenceName: {
    marginBottom: 6,
    fontSize: 22,
    fontWeight: "bold",
  },
});

export default function EditResidenceScreen() {
  const theme = useTheme();
  const residence = useResidenceFromRoute();
  const organization = useAppSelector(selectOrganization);
  const user = useAppSelector(selectUser);
  const navigation = useAppNavigation();

  const goBack = useCallback(() => {
    navigation.navigate("Residence_List")
  }, [navigation]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isValid, dirtyFields, isSubmitting },
  } = useForm<IFormInput>({
    defaultValues: {
      note: "",
    },
  });
  const createAttachments = useCallback(
    async (images: Image[]) => {
      if (!dirtyFields.images || images.length == 0) return;

      const imageIds = images.map(() => uuidv4());
      // upload images
      images.forEach(async (image, index) => {
        const uuid = imageIds[index];
        const storageRef = ref(storage, uuid);
        const response = await fetch(image.imageUrl);
        const blob = await response.blob();
        await uploadBytes(storageRef, blob);
      });

      await runTransaction(db, async (transaction) => {
        images.forEach(async (image, index) => {
          const uuid = imageIds[index];
          const storageRef = ref(storage, uuid);
          const fullUrl = `gs://${storageRef.bucket}/${storageRef.fullPath}`
          const imageData: Attachment = {
            uuid,
            label: image.imageDescription,
            url: fullUrl,
            storageRefPath: uuid,
            createdAt: serverTimestamp(),
            fileName: image.fileName,
            contentType: image.contentType,
          };

          // append to case attachments ids array
          transaction.update(doc(db,
            `organizations/${
              organization.id
            }/residence_epochs/${
              organization.residencesEpochId
            }/residences/${
              residence.id
            }`), {
            attachments: arrayUnion(uuid),
          });

          // create attachment
          transaction.set(
            doc(db, `organizations/${
              organization.id
            }/residence_epochs/${
              organization.residencesEpochId
            }/residences/${
              residence.id
            }/attachments`, uuid),
            imageData
          );
        });
      });
    },
    [organization.id, organization.residencesEpochId, dirtyFields.images],
  );

  const handleCreate = useCallback((data: IFormInput) => {
    if (!user || !organization || !residence) {
      return Promise.reject(new Error("Invalid Path"));
    }

    return Promise.all([
      createAttachments(data.images),
      createNote({
        uid: user.id,
        text: data.note,
        parentPath: `organizations/${
          organization.id
        }/residence_epochs/${
          organization.residencesEpochId
        }/residences/${
          residence.id
        }`
      }),
    ])
      .then(goBack)
      .then(() => notifySaved());
  }, [residence, organization, user, goBack, createAttachments, createNote]);

  const updateDatabase = useCallback(async (data: IFormInput) => {
    handleCreate(data)
      .then(goBack)
      .then(() => notifySaved())
  }, [goBack, handleCreate]);

  return (
    <Pordal>
      <View>
        <Text style={stylesheet.residenceName}>{residence.name}</Text>
      </View>
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextInput
            label="Add Note"
            mode="outlined"
            multiline={true}
            onChangeText={onChange}
            value={value}
            // only android
            numberOfLines={4}
            style={{ minHeight: 120, marginHorizontal: 20 }}
          />
        )}
        name="note"
      />
      <ResidenceImagePicker control={control} />
      <Button
        mode="contained"
        color={theme.colors.primary}
        style={{
          borderRadius: 4,
          marginHorizontal: 20,
          marginVertical: 20,
        }}
        textColor={theme.colors.onPrimary}
        onPress={handleSubmit(updateDatabase)}
      >
        Save
      </Button>
    </Pordal>
  )
}
