import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Organization } from '../types';
import {
  subscribeOrganization,
  subscribeOrganizations,
  updateCurrentOrganization,
} from '../Firebase/queries/organizations';

import { AppDispatch, RootState } from './store';
import { notifyAndLogError } from './snackbarSlice';

export interface OrganizationState {
  organization: Organization | null;
  organizations: Organization[];
}

const initialState: OrganizationState = {
  organization: null,
  organizations: [],
};

const organization = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setOrganization: (state, action: PayloadAction<Organization | null>) => {
      state.organization = action.payload;
    },
    setOrganizations: (state, action: PayloadAction<Organization[]>) => {
      state.organizations = action.payload;
    },
  },
});

export const {
  setOrganization,
  setOrganizations,
} = organization.actions;

// Thunks

export const subscribeOrganizationToFirestore =
  (orgId: string) =>
  (dispatch: AppDispatch) =>
    subscribeOrganization(orgId).subscribe(
      (org: Organization | null) => dispatch(setOrganization(org)),
      (error: Error) => dispatch(notifyAndLogError(error))
    );

export const subscribeOrganizationsToFirestore =
  (uid: string) =>
  (dispatch: AppDispatch) =>
    subscribeOrganizations(uid).subscribe(
      (orgs: Organization[]) => dispatch(setOrganizations(orgs)),
      (error: Error) => dispatch(notifyAndLogError(error))
    );

export const changeCurrentOrganization = (orgId: string) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const uid = getState().user.user?.id;
    if (!uid) {
      return Promise.reject(new Error('User not found'));
    }

    return updateCurrentOrganization(uid, orgId)
  }
}

// Selectors
export const selectOrganization = (state: RootState) =>
  state.organization.organization;

export const selectOrganizations = (state: RootState) =>
  state.organization.organizations;

export default organization.reducer;

